import React from "react";
import { Modal } from "react-bootstrap";

// import config from "../../assets/data/videoitem";

import medicalmoffering from "../../assets/images/subpages/cme-offering-img.jpg";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
       
      </Modal.Body>
    </Modal>
  );
}

function Medicalofferingvideo() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
       <div
        onKeyDown={() => setModalShow(true)}
        onClick={() => setModalShow(true)}
        role="button"
        tabIndex="0"
      >
        <img className="img-fluid hfn-offer-img" src={medicalmoffering} alt="youtube" />
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Medicalofferingvideo;
