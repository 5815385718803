import React from "react";

import Offeringvideo from "../../components/subpages/offeringdaijivideo";

import Offeringudemyclass from "../../components/subpages/offeringudemyclass";

import Heartsappvideo from "../../components/subpages/heartsappvideo";

import Magazieofferingvideo from "../../components/subpages/mahazineofferingvideo";

import Brighterofferingvideo from "../../components/subpages/brightermindsoffering";

import Schoolofferingvideo from "../../components/subpages/schoolconnectoffering";

import Teacherofferingvideo from "../../components/subpages/teacheroffering";

import Corporateofferingvideo from "../../components/subpages/corporateoffering";

import Medicalofferingvideo from "../../components/subpages/medicaloffering";

import retreatpicoffering from "../../assets/images/subpages/retreat-offering-img.jpg";

import Greenofferingvideo from "../../components/subpages/greenoffering";

import Wisdomofferingvideo from "../../components/subpages/wisdomoffering";

import rejuvenation from "../../assets/images/subpages/resilience-rejuvenation-banner-img-1.png";

import { Link } from "gatsby";

export default function Heartfulnessofferingpage() {
  return (
    <section className="heartfulnessoffering-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p className="theme-p-tag text-center ">
              {" "}
              Twelve ways to deepen your spiritual awareness, <br /> built on
              7,000 years of spiritual wisdom. Let’s begin.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="the-hfn-offerings-sec mb-5">
              <div className="the-hfn-offering-size">
                <div className="the-hfn-offering-header text-center">
                  <h6 className="mobile-text-font font-medium m-0">
                    <Link
                      to="/masterclass"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Experience Meditation
                    </Link>
                  </h6>
                </div>
                <h5 className="mobile-text-font  font-book big-p-wrpper">
                  Daaji’s Masterclasses
                </h5>

                <Offeringvideo />

                <p className="theme-p-tag mg-bottom60 hfn-offering-content">
                  {" "}
                  Learn to meditate the Heartfulness Way with 3 introductory
                  experiential sessions, as well as some great explanations from
                  Daaji about each practice.
                </p>
                <hr />
                <div className="center-item-offering mg-bottom20">
                  {/* <button className="btn btn-blue"> Begin </button> */}
                  <Link
                    className="btn btn-red"
                    to="/masterclass"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{    color: "white",fontSize: "19px !important",padding: "8px 43px"}}
                  >
                    Begin
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="the-hfn-offerings-sec mb-5">
              <div className="the-hfn-offering-size">
                <div className="the-hfn-offering-header text-center">
                  <h6 className=" mobile-text-font font-medium m-0">
                    <Link
                      to="https://www.daaji.org/udemy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Understand Meditation
                    </Link>
                  </h6>
                </div>
                <h5 className="mobile-text-font  font-book big-p-wrpper">Our Udemy class</h5>

                <Offeringudemyclass />

                <p className="theme-p-tag mg-bottom60 hfn-offering-content">
                  {" "}
                  A dynamic and practical online course with Daaji, covering
                  Heartfulness practices and tips for a well-balanced lifestyle.
                  <br/> <br/>
                
                </p>
                <hr />
                <div className="center-item-offering mg-bottom20">
                  <Link
                    className="btn btn-red"
                    to="http://www.daaji.org/udemy"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{    color: "white",fontSize: "19px !important",padding: "8px 43px"}}
                  >
                    Enroll
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="the-hfn-offerings-sec mb-5">
              <div className="the-hfn-offering-size">
                <div className="the-hfn-offering-header text-center">
                  <h6 className="mobile-text-font font-medium m-0">
                    <Link
                      to="https://play.google.com/store/apps/details?id=com.hfn.unified"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Meditation on-the-go
                    </Link>
                  </h6>
                </div>
                <h5 className="mobile-text-font  font-book big-p-wrpper">HeartsApp</h5>

                <Heartsappvideo />

                <p className="theme-p-tag mg-bottom60 hfn-offering-content">
                  {" "}
                  Download this easy-to-use App to connect with a certified
                  Heartfulness Trainer any time, any place. Meditate and receive
                  Transmission in the comfort of your own home or at work.
                </p>
                <hr />
                <div className="center-item-offering mg-bottom20">
                  <Link
                    to="https://play.google.com/store/apps/details?id=com.hfn.unified"
                    target="_blank"
                    className="btn btn-red"
                    rel="noopener noreferrer"
                    style={{    color: "white",fontSize: "19px !important",padding: "8px 43px"}}
                  >
                    Android
                  </Link>
                  <Link
                    className="btn btn-red"
                    to="https://itunes.apple.com/us/app/heartsapp-by-heartfulness/id1438627629?mt=8"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{marginLeft:"10px", color: "white",fontSize: "19px !important",padding: "8px 43px"}}
                  >
                    iOS
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="the-hfn-offerings-sec mb-5">
              <div className="the-hfn-offering-size">
                <div className="the-hfn-offering-header text-center">
                  <h6 className="mobile-text-font font-medium m-0">
                    <Link
                      to="https://play.google.com/store/apps/details?id=com.hfn.unified"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Monthly Heartfulness
                    </Link>
                  </h6>
                </div>
                <h5 className="mobile-text-font  font-book big-p-wrpper">
                  {" "}
                  Heartfulness Magazine{" "}
                </h5>

                <Magazieofferingvideo />

                <p className="theme-p-tag mg-bottom60 hfn-offering-content">
                  {" "}
                  Filled with interesting articles and lifestyle tips on
                  personal development, relationships, workplace and career,
                  spiritual well-being and the environment.
                </p>
                <hr />
                <div className="center-item-offering mg-bottom20">
                  <Link
                    className="btn btn-red"
                    to="http://www.heartfulnessmagazine.com/subscribe/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{    color: "white",fontSize: "19px !important",padding: "8px 43px"}}
                  >
                    Subscribe
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="the-hfn-offerings-sec mb-5">
              <div className="the-hfn-offering-size">
                <div className="the-hfn-offering-header text-center">
                  <h6 className="mobile-text-font font-medium m-0">
                    <Link
                      to="https://www.brighterminds.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                      
                    >
                      Child Development
                    </Link>
                  </h6>
                </div>
                <h5 className="mobile-text-font  font-book big-p-wrpper"> Brighter Minds </h5>

                <Brighterofferingvideo />

                <p className="theme-p-tag mg-bottom60 hfn-offering-content">
                  {" "}
                  Nurturing the potential of children’s minds and hearts – their
                  cognitive and problem-solving abilities, intuition, creativity
                  and consciousness.
                  <br/><br/>
                </p>
                <hr />
                <div className="center-item-offering mg-bottom20">
                  <Link
                    className="btn btn-red"
                    to="https://www.brighterminds.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{    color: "white",fontSize: "19px !important",padding: "8px 43px"}}
                  >
                    Begin
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="the-hfn-offerings-sec mb-5">
              <div className="the-hfn-offering-size">
                <div className="the-hfn-offering-header text-center">
                  <h6 className="mobile-text-font font-medium m-0">
                    <Link
                      to="https://heartfulness.org/education/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Heartful Students
                    </Link>
                  </h6>
                </div>
                <h5 className="mobile-text-font  font-book big-p-wrpper"> School-Connect </h5>

                <Schoolofferingvideo />

                <p className="theme-p-tag mg-bottom60 hfn-offering-content">
                  {" "}
                  A dynamic set of fun interactive activities for students in
                  Years 1 to 10, guiding them towards a life of balance, wise
                  choices and happiness.<br/><br/>
                </p>
                <hr />
                <div className="center-item-offering mg-bottom20">
                  <Link
                    className="btn btn-red"
                    to="http://education.heartfulness.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{    color: "white",fontSize: "19px !important",padding: "8px 43px"}}
                  >
                    Engage
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="the-hfn-offerings-sec mb-5">
              <div className="the-hfn-offering-size">
                <div className="the-hfn-offering-header text-center">
                  <h6 className="mobile-text-font font-medium m-0">
                    <Link
                      to="http://education.heartfulness.org/yoga-trainers/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Heartful Teachers
                    </Link>
                  </h6>
                </div>
                <h5 className="mobile-text-font  font-book big-p-wrpper">
                  {" "}
                  Teachers’ Training Programs{" "}
                </h5>

                <Teacherofferingvideo />

                <p className="theme-p-tag mg-bottom60 hfn-offering-content">
                  {" "}
                  Explore and experience the 8 limbs of Ashtanga Yoga. Become a
                  certified Heartfulness Yoga Teacher. One-month residential
                  program.<br/><br/>
                </p>
                <hr />
                <div className="center-item-offering mg-bottom20">
                  <Link
                    className="btn btn-red"
                    to="http://education.heartfulness.org/yoga-trainers/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{    color: "white",fontSize: "19px !important",padding: "8px 43px"}}
                  >
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="the-hfn-offerings-sec mb-5">
              <div className="the-hfn-offering-size">
                <div className="the-hfn-offering-header text-center">
                  <h6 className="mobile-text-font font-medium m-0">
                    <Link
                      to="https://heartfulness.org/en/kaushalam/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Heartful Teachers
                    </Link>
                  </h6>
                </div>
                <h5 className="mobile-text-font  font-book big-p-wrpper">
                  {" "}
                  Corporate <br/>Leadership{" "}
                </h5>

                <Corporateofferingvideo />

                <p className="theme-p-tag mg-bottom60 hfn-offering-content">
                  {" "}
                  A program for leaders in the corporate world who are striving
                  for excellence in their own work and in the teams they lead.<br/>
                </p>
                <hr />
                <div className="center-item-offering mg-bottom20">
                  <Link
                    className="btn btn-red"
                    to="https://heartfulness.org/in/kaushalam/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{    color: "white",fontSize: "19px !important",padding: "8px 43px"}}
                  >
                    Study
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="the-hfn-offerings-sec mb-5">
              <div className="the-hfn-offering-size">
                <div className="the-hfn-offering-header text-center">
                  <h6 className="mobile-text-font font-medium m-0">
                    <Link
                      to="https://www.heartfulnessinstitute.org/medical"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Medical Professionals
                    </Link>
                  </h6>
                </div>
                <h5 className="mobile-text-font  font-book big-p-wrpper">
                  {" "}
                  Continual Medical Education{" "}
                </h5>

                <Medicalofferingvideo />

                <p className="theme-p-tag mg-bottom60 hfn-offering-content">
                  {" "}
                  Medical professionals learn how to tap in to their inner
                  resources and look after themselves, increasing their capacity
                  to help others and overcome compassion fatigue.
                </p>
                <hr />
                <div className="center-item-offering mg-bottom20">
                  <Link
                    className="btn btn-red"
                    to="https://www.heartfulnessinstitute.org/medical"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{    color: "white",fontSize: "19px !important",padding: "8px 43px"}}
                  >
                    Details
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="the-hfn-offerings-sec mb-5">
              <div className="the-hfn-offering-size">
                <div className="the-hfn-offering-header text-center">
                  <h6 className="mobile-text-font font-medium m-0">
                    <Link
                      to="https://heartfulness.org/in/wellness-retreats"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Wellness
                    </Link>
                  </h6>
                </div>
                <h5 className="mobile-text-font  font-book big-p-wrpper">
                  {" "}
                  Heartfulness Retreats{" "}
                </h5>
                <div>
                <img
                  className="img-fluid hfn-offer-img"
                  src={retreatpicoffering}
                  alt="youtube"
                />
</div>
                <p className="theme-p-tag mg-bottom60 hfn-offering-content">
                  {" "}
                  Take the weekend off and make simple adjustments in your
                  lifestyle with our health experts. Nutritious life-giving
                  food, Yoga, meditation techniques – a rejuvenating experience.<br/><br/>
                </p>
                <hr />
                <div className="center-item-offering mg-bottom20">
                  <Link
                    className="btn btn-red"
                    to="https://heartfulness.org/in/wellness-retreats/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{    color: "white",fontSize: "19px !important",padding: "8px 43px"}}
                  >
                    Know more
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="the-hfn-offerings-sec mb-5">
              <div className="the-hfn-offering-size">
                <div className="the-hfn-offering-header text-center">
                  <h6 className="mobile-text-font font-medium m-0">
                    <Link
                      to="https://heartfulness.org/kanha/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kanha Shantivanam
                    </Link>
                  </h6>
                </div>
                <h5 className="mobile-text-font  font-book big-p-wrpper"> Heartfulness Green </h5>

                <Greenofferingvideo />

                <p className="theme-p-tag mg-bottom60 hfn-offering-content">
                  {" "}
                  The international center for Yoga, meditation and sustainable
                  living in tune with Nature. Kanha is a center of learning and
                  excellence for personal inner growth, lifestyle changes and
                  the well-being of Planet Earth.
                </p>
                <hr />
                <div className="center-item-offering mg-bottom20">
                  <Link
                    className="btn btn-red"
                    to="https://heartfulness.org/en/green/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{    color: "white",fontSize: "19px !important",padding: "8px 43px"}}
                  >
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="the-hfn-offerings-sec mb-5">
              <div className="the-hfn-offering-size">
                <div className="the-hfn-offering-header text-center">
                  <h6 className="mobile-text-font font-medium m-0">
                    <Link
                      to="https://heartfulness.org/en/subscribe-to-one-beautiful-thought/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      One Beautiful Thought
                    </Link>
                  </h6>
                </div>
                <h5 className="mobile-text-font  font-book big-p-wrpper">
                  {" "}
                  Wisdom of the Masters{" "}
                </h5>

                <Wisdomofferingvideo />

                <p className="theme-p-tag mg-bottom60 hfn-offering-content">
                  {" "}
                  How would you like to start your day, every day, with an
                  inspiring quote from one of the Heartfulness Guides, to help
                  you absorb the condition you have received in morning
                  meditation?<br/><br/>
                </p>
                <hr />
                <div className="center-item-offering mg-bottom20">
                  <Link
                    to="/subscribe-to-one-beautiful-thought"
                    target="_blank"
                    className="btn btn-red subscribe-btn"
                    rel="noopener noreferrer"
                  
                  >
                    Subscribe
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="the-hfn-offerings-sec mb-5">
              <div className="the-hfn-offering-size">
                <div className="the-hfn-offering-header text-center">
                  <h6 className="mobile-text-font font-medium m-0">
                    <Link
                      to="https://heartfulness.org/kanha/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Heartfulness At Work
                    </Link>
                  </h6>
                </div>
                <h5 className="mobile-text-font  font-book big-p-wrpper">
                  {" "}
                  Heartfulness for Organisations{" "}
                </h5>
                <div>
                <img
                  className="img-fluid hfn-offer-img"
                  src={rejuvenation}
                  alt="youtube"
                />
</div>
                <p className="theme-p-tag mg-bottom60 hfn-offering-content">
                  {" "}
                  Range of programs for individual wellness and inner
                  transformation. Participants learn to achieve a natural state
                  of focus and balance, build inner strength and develop habits
                  to create a lasting sense of fulfilment and overall
                </p>
                <hr />
                <div className="center-item-offering mg-bottom20">
                  <Link
                    className="btn btn-red"
                    to="https://heartfulness.org/heartfulness-at-work/"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{    color: "white",fontSize: "19px !important",padding: "8px 43px"}}
                  >
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-center">
            <p className="theme-p-tag">
              {" "}
              For any further information or to host Heartfulness meditation programs at your organisation, school, college or location Please contact
               {" "}
               <a className="link-color" href="mailto:devanr@volunteer.heartfulness.org ">devanr@volunteer.heartfulness.org</a> <br/> 
              <a className="link-color" href="mailto: rsa.secretary@heartfulness.org "> rsa.secretary@heartfulness.org </a> <br/> 
              <a className="link-color" href="mailto: za.secretary@srcm.org ">   za.secretary@srcm.org</a>
            {" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
