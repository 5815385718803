import React from "react";

export default function Offeringbanner() {
  return (
    <div className="offering-banner-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center">
              <h3 className="heading-wrapper white-text line-height50">
                {" "}
                FOR YOUR <br /> SPIRITUAL AWAKENING, <br /> TWELVE SUNRISES
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
