import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Heartfulnessofferingpage from "../components/subpages/heartfulnessofferingcointent";

import Offerbreadcreumb from "../components/subpages/offerbreadcumb";

import Offeringbanner from "../components/subpages/offeringbanner";

// markup
const heartfulnessofferingPage = () => {
  return (
    <div>
      <Seo title="HEARTFULNESS OFFERINGS" />

      <Layout isSticky>
        <section>
          <Offerbreadcreumb />
        </section>
        <section>
          <Offeringbanner />
        </section>
        <section>
          <Heartfulnessofferingpage />
        </section>
      </Layout>
    </div>
  );
};

export default heartfulnessofferingPage;
